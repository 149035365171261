<template>
  <div class="detail">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <div class="detail-title">联系我们</div>
      <div class="detail-text">
        <div>国家技术标准创新基地（民航）秘书处</div>
        <div>地址：北京市朝阳区西坝河北里甲24号中国民航科学技术研究院</div>
        <div>邮编：100028</div>
        <div>电话：010-64473160，010-64473128。</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  name: 'Contact',
  data () {
    return {
      breadcrumbList: [
        { name: '首页', path: '/' },
        { name: '联系我们', path: '' }

      ],
      articleData: ''
    }
  },
  created () {
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="less" scoped>
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 40px 80px;
  min-height: 520px;
  background-color: #ffffff;
}
.detail-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;
  margin-bottom: 50px;
}
.detail-source{
  color: #999999;
  padding-top: 50px;
  text-align: right;
}
.detail-text{
  font-size: 18px;
  padding-left: 35px;
}
.detail-text div{
  line-height: 35px;
}
</style>
